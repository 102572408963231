
import { request,METHOD } from '@/utils/request.js'

const apiList = {
  selectSalaryScreen: '/business/salary/selectSalaryScreen',
  currCompanyShowDetailInfo: '/business/companyScreenConfig/currCompanyShowDetailInfo',
  screenSwitchInfo:'/business/companyScreenConfig/screenSwitchInfo', 
  getRewardPunish:'/business/regulation/selectLargeScreenRegulationList', 
  getRewardDetail:'/business/regulation/sheetLargeScreenRegulationDetail', 
  getAssessmentDetail:'/business/examine/selectHistoryExamineDetailList', 
  getAssessmentList:'/business/examine/selectSubExamineList', 

  
}

export function getHomeData(parameter) {
  return request({
    url: '/business/reform/selectLargeScreenManagementHome',
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function getAttendance(parameter) {
  return request({
    url: '/business/clockin/projectUserClockInList',
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function getReformList(parameter) {
  return request({
    url: '/business/reform/selectLargeScreenReformList',
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function screenSwitchInfo(parameter) {
  return request({
    url: apiList.screenSwitchInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function getRewardPunish(parameter) {
  return request({
    url: apiList.getRewardPunish,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function getRewardDetail(parameter) {
  return request({
    url: apiList.getRewardDetail,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function getAssessmentDetail(parameter) {
  return request({
    url: apiList.getAssessmentDetail,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}



export function getAssessmentList(parameter) {
  return request({
    url: apiList.getAssessmentList,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}


