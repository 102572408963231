<template>
  <div>
    <div class="top-select flex space-between">
      <div class="projectName" >项目：{{ proName }}</div>
      <div class="time-box">
        <el-date-picker
          v-model="date"
          type="date"
          placeholder="选择日期"
          class="datePicker"
          @change="changeDate"
          value-format="yyyy-MM-dd"
          :clearable="false"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="scroll_table">
      <!-- <dv-border-box-8 :dur="10" style="padding: 10px; height: 800px"> -->
      <div class="table-container" style="width: 100%">
        <el-table
          :data="tableList"
          :header-cell-style="{
            background: 'rgba(13,29,77,0.8)',
            color: '#fff',
            'border-bottom': 'none',
            border: '1px solid rgb(91,111,143)',
            height: '60px',
          }"
          class="table"
        >
          <el-table-column
            align="center"
            label="姓名"
            prop="name"
          ></el-table-column>
          <el-table-column
            align="center"
            label="要求与整改单（张）"
            prop="name"
          ></el-table-column>
          <el-table-column
            align="center"
            label="奖罚单（张）"
            prop="name"
          ></el-table-column>
          <el-table-column align="center" label="下级考核"></el-table-column>
          <el-table-column label="考勤情况" align="center"></el-table-column>
          <el-table-column label="工作轨迹" align="center"></el-table-column>
          <el-table-column label="星标等级" align="center"></el-table-column>
        </el-table>
        <scroll-table
          :data="tableList"
          class="seamless-warp"
          ref="scrollTable"
          :style="{
            width: '100%',
            height: rowNum * 63 + 'px',
          }"
          style="transition: all 0.5s"
          v-if="showTable"
          :classOption="classOption"
        >
          <el-table
            cell-class-name="row-cell"
            :data="tableList"
            class="table_scroll"
            @click.stop
          >
            <el-table-column
              align="center"
              label="姓名"
              prop="name"
              :formatter="(row) => row.name + `（${row.postName}）`"
            ></el-table-column>
            <el-table-column
              align="center"
              label="要求与整改单（张）"
              prop="reformNumber"
            >
              <template slot-scope="scope">
                <el-button
                  class="cell-number"
                  type="text"
                  size="small"
                  @click.stop="handleRectification(scope.row)"
                  >{{ scope.row.reformNumber }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="奖罚单（张）"
              prop="regulationNumber"
            >
              <template slot-scope="scope">
                <el-button
                  class="cell-number"
                  type="text"
                  size="small"
                  @click="handleRewardList(scope.row)"
                  >{{ scope.row.regulationNumber }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column align="center" label="下级考核"
              >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="cell-text"
                  @click="handleAssessment(scope.row)"
                  >{{ scope.row.rightKH }}</el-button
                >
              </template>
              </el-table-column
            >
            <el-table-column align="center" label="考勤情况"
              >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="cell-text"
                  @click="handleDetail(scope.row)"
                  >{{ scope.row.rightKQ }}</el-button>
              </template>
              </el-table-column
            >
            <el-table-column align="center" label="工作轨迹">
              <template slot-scope="">
                <el-button
                  type="text"
                  size="small"
                  class="cell-text"
                  @click="showMessage"
                  >进入详情</el-button>
              </template>
              </el-table-column>
            <el-table-column align="center" label="星标等级">
              <template slot-scope="scope">
                <div v-if="scope.row.averageStar == '无'">
                  无
                </div>
                <el-rate
                  v-else
                  v-model="scope.row.averageStar"
                  disabled
                  show-score
                  text-color="#ff9900"
                  >
                </el-rate>
              </template>
            </el-table-column>
          </el-table>
        </scroll-table>
      </div>
      <!-- </dv-border-box-8> -->
    </div>
  </div>
</template>
<script>
import { getHomeData } from "./api.js";
// import { mapState } from "vuex";
// import TopHeader from "./components/top-header.vue";
import ScrollTable from "./components/scroll-table.vue";
export default {
  components: {
    // TopHeader,
    ScrollTable,
  },
  data() {
    return {
      date: new Date(),
      columns: [
        { prop: "name", label: "姓名", minWidth: "100", sort: true },
        {
          prop: "date",
          label: "要求与整改单（张）",
          minWidth: "180",
          sort: true,
        },
        { prop: "age", label: "奖罚单（张）", minWidth: "180", sort: true },
      ],
      tableList: [],
      scrollTimer: null,
      cellName(cell) {
        if (cell.columnIndex == 1 || cell.columnIndex == 2) {
          return "cell-number";
        }
      },
      showTable: false,
      extraRow: 0,
      projectId:"",
      proName:"",
    };
  },

  computed: {
    // ...mapState(["project"]),

    classOption() {
      return {
        step: 2, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 4000, // 单步运动停止的时间(默认值1000ms)
        singleHeight: 60,
        openTouch: true,
      };
    },
  },
  mounted() {
    this.projectId = sessionStorage.getItem('projectId')
    this.proName = sessionStorage.getItem('companyName')
    // this.scrollTable();
    // this.date = this.parseTime(new Date(), "{y}-{m}-{d}");
      this.getData();

  },
  
  methods: {
    getData() {
      getHomeData({
        projectId: this.projectId,
        time: this.date,
        pageSize: 9999,
      }).then((res) => {
        this.tableList = res.data.rows;
        this.tableList.forEach(item=>{
          if(item.averageStar != "无"){
           item.averageStar = parseFloat(item.averageStar)
          }
        })
        this.$nextTick(() => {
          this.calcHeight();
        });
      });
    },

    handleDetail(row) {
      sessionStorage.setItem('dates',this.date)
      if (row.rightKQ !== "--") {
        this.$router.push({
          path: "/managerScreen/detail",
          query: { ...row, date: this.date },
        });
      }
    },
    handleRectification(row) {
      // 要求与整改单
      sessionStorage.setItem('dates',this.date)
      if (row.reformNumber != "--") {
        this.$router.push({
          path: "/managerScreen/rectification",
          query: { ...row, date: this.date },
        });
      }
    },
    handleRewardList(row) {
      sessionStorage.setItem('dates',this.date)
      if (row.reformNumber != "--") {
        this.$router.push({
          path: "/managerScreen/rewardPunish",
          query: { ...row, date: this.date },
        });
      }
    },
    changeDate() {
      this.getData();
    },
    handleAssessment(row) {
      sessionStorage.setItem('dates',this.date)
      if (row.reformNumber != "--") {
        this.$router.push({
          path: "/managerScreen/assessment",
          query: { ...row, date: this.date },
        });
      }
    },
    calcHeight() {
      this.showTable = false;
      setTimeout(() => {
        const myElement = document.getElementsByClassName("top-select")[0];
        const rect = myElement.getBoundingClientRect();
        const distanceToBottom = window.innerHeight - rect.bottom;
        let rowNum = Math.floor((distanceToBottom - 100) / 60);
        let listLength = this.tableList.length;
        this.rowNum = Math.min(rowNum, listLength)<1?1:Math.min(rowNum, listLength);
        console.log(this.rowNum,"rowNum")
        this.showTable = true;
      }, 200);
    },
    handleResize() {
      this.calcHeight();
    },
    showMessage() {
      const h = this.$createElement;
        this.$message({
          message: h('p', null, [
            h('span', {style:{color:'#000'}}, '该功能暂未开放，敬请等待！ '),
          ])
        });
    }
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
::v-deep .table-cell {
  border: 1px solid rgb(91, 111, 143);
}

::v-deep .cell-text {
  font-size: 16px;
  color: rgb(128, 180, 224);
}
::v-deep .cell-number {
  font-size: 18px;
  font-weight: bold;
  color: rgb(128, 180, 224);
}
.datePicker ::v-deep .el-input__inner {
  background: rgba(0, 0, 0, 0);
  color: #efefef;
  // width: 2.22rem;
}
::v-deep .el-table {
  background: rgba(0, 0, 0, 0);
}
::v-deep .el-table tr {
  background: rgba(0, 0, 0, 0);
}
.scroll_table {
  margin: 15px 0;
  display: flex;
  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }
  // 重点注意这段样式
  .seamless-warp {
    height: 200px;
    overflow: hidden;
    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }
}
.flex{
  display: flex;
}
.space-between{
  justify-content: space-between;
}
.projectName{
  // width: 4rem;
  // height: 1rem;
  // text-align-last: left;
}
.time-box{
  display: flex;
  justify-content: center;

}
.fontsize18{
  // font-size: 0.18rem;
}
</style>
